<template>
	<div class="about-contain counter my-16">
		<v-row>
			<v-col
				v-for="(count, n) in counters.c"
				:key="n"
				cols="12"
				md="4"
				data-aos="flip-right"
				data-aos-duration="1000"
			>
				<div class="CounterContent">
					<div>
						<p class="counterCount display-2">
							<ICountUp
								ref="countup"
								v-intersect.once="onIntersect"
								:start-val="startVal"
								:end-val="endCount(count)"
								:decimals="decimals"
								:duration="duration"
								:options="options"
							/>
						</p>
					</div>
					<p class="counterTitle display-1">
						{{ counters.l[n] }}
					</p>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import ICountUp from "vue-countup-v2";

export default {
	components: {
		ICountUp,
	},
	props: {
		// eslint-disable-next-line vue/require-default-prop
		counters: { type: Object, required: false },
	},
	data() {
		return {
			counter: {
				displayNumber: 0,
				interval: false,
			},
			startVal: 0,
			endVal: 0,
			decimals: 0,
			duration: 10,
			options: {
				useEasing: false,
				useGrouping: false,
				separator: ",",
				decimal: ".",
				prefix: "",
				suffix: "",
			},
		};
	},
	mounted() {
	},
	methods: {
		onIntersect(entries) {
			if (entries[0].isIntersecting) {
				for (let i = 0; i < this.$refs.countup.length; i += 1) {
					this.$refs.countup[i].reset();
					this.$refs.countup[i].start();
				}
			}
		},
		endCount(count) {
			return parseInt(count, 10);
		},
	},
};
</script>

<style scoped>
.CounterContent{
	text-align: center;
	justify-content: center;
	align-items: center;
}
.counterTitle{
	color: var(--v-primary-base);
}
.counterCount{
	color: var(--v-secondary-base);
}
</style>
