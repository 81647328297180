<template>
	<div class="about-contain feature my-16">
		<v-row>
			<v-col
				v-for="(image , n) in features.i"
				:key="n"
				cols="12"
				md="4"
				data-aos="flip-left"
				data-aos-duration="1000"
			>
				<div class="features" />
				<div class="features">
					<img
						:src="image"
						class="featureImage"
					>
					<div class="FeatureContent">
						<h3 class="featureTitle">
							{{ features.l[n] }}
						</h3>
						<p class="text--secondary">
							{{ features.c[n] }}
						</p>
					</div>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	props: {
		// eslint-disable-next-line vue/require-default-prop
		features: { type: Object, required: false },
	},
	data() {
		return {
			page: {
				imgLoad: false,
			},
		};
	},
};
</script>

<style scoped>
.featureImage{
	max-width: 64px;
	max-height: 64px;
	height:100%;
	width: 100%;
}
.features{
	display: flex;
}
.featureTitle{
	color: var(--v-primary-base);
}
.FeatureContent{
	display: flex;
	flex-direction: column;
	margin-left: 20px;
}
</style>
