<template>
	<div
		v-resize="onResize"
		class="Home"
	>
		<div
			v-if="!apiRes()"
			class="text-center"
		>
			<v-overlay
				:value="true"
				:opacity="1"
			>
				<v-progress-circular
					indeterminate
					size="64"
				/>
			</v-overlay>
		</div>

		<div
			v-else
			class="mainContant"
		>
			<div
				v-if="page.data.banner.banner_images"
				id="home"
				v-intersect="onIntersect"
				class="banner primaryBG"
			>
				<div class="slider">
					<Banner
						:banner="page.data.banner.banner_images"
						:tiny="page.data.banner.tiny_images"
					/>
				</div>
				<div>
					<FindYourVoyages
						:country-drop-down="tours.countryDropDown"
						:loading="!apiCall.tour"
						:place="tours.search.words"
						:country="tours.search.country"
						:deals="tours.search.deals"
						@searchData="search"
					/>
				</div>
			</div>

			<div
				class="deals-discount primaryBG"
			>
				<v-container>
					<v-row>
						<v-col cols="12">
							<p class="display-3 font-weight-bold text-center">
								Deals and Discount
							</p>
						</v-col>
					</v-row>

					<v-row
						data-aos="zoom-in-up"
						data-aos-duration="1000"
					>
						<v-col
							cols="12"
						>
							<div class="example-3d">
								<swiper
									class="swiper"
									:options="page.tour.swiperOption"
								>
									<swiper-slide
										v-for="(tour , n) in tours.data"
										:key="n"
										class="my-8"
									>
										<TourCard
											:tour-data="tour"
											:skeleton="false"
										/>
									</swiper-slide>
									<div
										slot="pagination"
										class="swiper-pagination"
									/>
								</swiper>
							</div>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<div class="seeAllbtn text-center">
								<v-btn
									class="primary"
									@click="showAllDeals"
								>
									See All
									<v-icon class="ml-2">
										mdi-chevron-right
									</v-icon>
								</v-btn>
							</div>
						</v-col>
					</v-row>
				</v-container>
			</div>

			<div
				v-if="page.data.tourType.length > 0"
				class="popular-tour secondaryBG py-8"
			>
				<v-container>
					<v-row>
						<v-col cols="12">
							<p class="display-3 font-weight-bold text-center">
								Popular Tour Type
							</p>
						</v-col>
					</v-row>

					<v-row

						data-aos="fade-right"
						data-aos-duration="1000"
					>
						<v-col cols="12">
							<PopularTour
								:tour-type="page.data.tourType"
							/>
						</v-col>
					</v-row>
				</v-container>
			</div>

			<div
				id="review"
				class="top-review primaryBG py-8"
			>
				<v-container>
					<v-row>
						<v-col cols="12">
							<p class="display-3 font-weight-bold text-center">
								Top Reviews
							</p>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<Review :reviews="page.data.testimonial" />
						</v-col>
					</v-row>
				</v-container>
			</div>

			<div
				v-if="Object.keys(page.data.about).length > 0"
				id="about"
				ref="about"
				class="about-us secondaryBG"
			>
				<v-container class="about-container">
					<div class="about-contain title my-16">
						<p class="display-3 font-weight-bold text-center">
							About US
						</p>
					</div>

					<div
						class="about-contain content my-16"
					>
						<p class="text--secondary">
							<!-- eslint-disable-next-line vue/no-v-html -->
							<span v-html="page.data.about.content" />
						</p>
					</div>

					<div class="about-contain title my-16">
						<p class="display-3 font-weight-bold text-center">
							Why Elite Voyages ?
						</p>
					</div>

					<Features
						:features="page.data.about.feature"
					/>

					<Counter
						:counters="page.data.about.counter"
					/>
				</v-container>
				<div class="aboutBG">
					<CounterSVG />
				</div>
			</div>

			<div
				v-if="page.data.certificate.length > 0"
				class="certificate primaryBG"
			>
				<v-container>
					<v-row class="my-4">
						<v-col cols="12">
							<p class="display-3 font-weight-bold text-center">
								Certificate
							</p>
						</v-col>
					</v-row>

					<v-row
						class="my-4"
						data-aos="zoom-in-up"
						data-aos-duration="1000"
					>
						<v-col cols="12">
							<swiper
								v-if="page.data.certificate.length > 4"
								class="swiper"
								:options="page.certificate.swiperOption"
							>
								<swiper-slide
									v-for="(certificate , n) in page.data.certificate"
									:key="n"
								>
									<div class="certificate-img my-4 ml-auto mr-auto">
										<img
											v-show="page.certificate.imgLoad"
											:src="certificate"
											class="ml-auto mr-auto"
											width="256"
											height="auto"
											@load="page.certificate.imgLoad = true"
										>
									</div>
								</swiper-slide>

								<div
									slot="pagination"
									class="swiper-pagination"
								/>
							</swiper>

							<v-row v-else>
								<v-col
									v-for="(certificate , n) in page.data.certificate"
									:key="n"
									cols="12"
									md="6"
									lg="3"
								>
									<div
										class="certificate-img ml-auto mr-auto"
									>
										<img
											v-show="page.certificate.imgLoad"
											:src="certificate"
											class="ml-auto mr-auto"
											width="256"
											height="auto"
											@load="page.certificate.imgLoad = true"
										>
									</div>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-container>
			</div>

			<ContactUs
				id="contact"
				:map-url="page.data.contact.mapUrl"
				:res-msg="page.contactRes"
				@contactSubmit="contactSubmit"
			/>
		</div>
	</div>
</template>

<script>
import Banner from "components/Banner";
import ContactUs from "components/ContactUs";
import Counter from "components/Counter";
import Features from "components/Features";
import FindYourVoyages from "components/FindYourVoyages";
import PopularTour from "components/PopularTour";
import Review from "components/Review";
import CounterSVG from "components/svg/CounterSVG";
import TourCard from "components/TourCard";

import { api, baseMixins, toursMixin } from "mixins";

export default {
	components: {
		// eslint-disable-next-line vue/no-unused-components
		TourCard,
		FindYourVoyages,
		Banner,
		PopularTour,
		Review,
		Features,
		Counter,
		// eslint-disable-next-line vue/no-unused-components
		CounterSVG,
		ContactUs,
	},
	mixins: [baseMixins, api, toursMixin],
	data() {
		return {
			page: {
				contactRes: null,
				tour: {
					swiperOption: {
						init: true,
						effect: "coverflow",
						slidesPerView: 3,
						centeredSlides: false,
						spaceBetween: 30,
						loop: true,
						speed: 800,
						loopAdditionalSlides: 0,
						loopedSlides: 0,
						coverflowEffect: {
							rotate: 50,
							stretch: 0,
							depth: 100,
							modifier: 1,
							slideShadows: false,
						},
						pagination: {
							el: ".swiper-pagination",
							dynamicBullets: true,
						},
						preloadImages: true,
						lazy: {
							loadPrevNext: true,
							loadOnTransitionStart: true,
							loadPrevNextAmount: 1,
						},
						watchSlidesVisibility: true,
						initialSlide: 0,
						autoplay: {
							delay: 3000,
							disableOnInteraction: false,
							autoplayDisableOnInteraction: false,
						},
						breakpoints: {
							1024: {
								slidesPerView: 3,
								spaceBetween: 30,
							},
							768: {
								slidesPerView: 3,
								spaceBetween: 30,
							},
							640: {
								slidesPerView: 3,
								spaceBetween: 20,
							},
							576: {
								slidesPerView: 1,
								spaceBetween: 10,
							},
							320: {
								slidesPerView: 1,
								spaceBetween: 10,
							},
						},
					},
				},
				certificate: {
					imgLoad: false,
					swiperOption: {
						slidesPerView: 4,
						spaceBetween: 30,
						centeredSlides: false,
						setWrapperSize: true,
						grabCursor: true,
						initialSlide: 0,
						loop: true,
						pagination: {
							el: ".swiper-pagination",
							clickable: true,
							dynamicBullets: true,
						},
						breakpoints: {
							1024: {
								slidesPerView: 3,
								spaceBetween: 30,
							},
							992: {
								slidesPerView: 3,
								spaceBetween: 30,
							},
							768: {
								slidesPerView: 3,
								spaceBetween: 20,
							},
							640: {
								slidesPerView: 2,
								spaceBetween: 20,
							},
							576: {
								slidesPerView: 1,
								spaceBetween: 10,
							},
							320: {
								slidesPerView: 1,
								spaceBetween: 10,
							},
						},
					},
				},
				data: {
					banner: {},
					about: {},
					certificate: [],
					contact: {},
					tourType: [],
					testimonial: [],
				},
				load: {
					home: false,
					testimonial: false,
				},
				counterVisiable: false,
			},
		};
	},
	created() {
		window.scrollTo(0, 0);
		this.getHomePage();
		this.getTestimonial();
		this.getDiscount();
		this.getCCDropDown();
	},
	mounted() {
		this.scrollToAnchor();
	},
	updated() {
		this.scrollToAnchor();
	},
	methods: {
		loadDone() {
			console.log("Call");
		},
		scrollToAnchor() {
			this.$nextTick(() => {
				if (this.$route.hash) {
					const $el = document.querySelector(this.$route.hash);
					if ($el) {
						window.scrollTo(0, $el.offsetTop);
					}
				}
			});
		},
		apiRes() {
			return this.page.load.home && this.page.load.testimonial && this.apiCall.tour && this.apiCall.cc;
		},
		getHomePage() {
			if (this.baseCheckBeforeCallApiFunction()) {
				this.page.load.home = false;
				this.callApi(this.$axios, "f/home/get", {}, { apiKey: "ev-f-ho-get" }).then((response) => {
					if (response && Object.keys(response).length > 0) {
						const aCode = response.about.code;
						if (aCode === 200) {
							this.page.data.about = response.about.body;
						}

						const bCode = response.banner.code;
						if (bCode === 200) {
							this.page.data.banner = response.banner.body;
						}

						const cCode = response.certificate.code;
						if (cCode === 200) {
							this.page.data.certificate = response.certificate.body.certificate;
						}

						const tCode = response.tourType.code;
						if (tCode === 200) {
							this.page.data.tourType = response.tourType.body.tourType;
						}

						const coCode = response.contact.code;
						if (coCode === 200) {
							this.page.data.contact = response.contact.body.contact;
						}
					}
					this.page.load.home = true;
				});
			}
		},
		getTestimonial() {
			if (this.baseCheckBeforeCallApiFunction()) {
				this.page.load.testimonial = false;
				this.callApi(this.$axios, "f/testimonial/get", {}, { apiKey: "ev-f-te-get" }).then((response) => {
					if (response && Object.keys(response).length > 0) {
						this.page.data.testimonial = response.value;
					}
					this.page.load.testimonial = true;
				});
			}
		},
		contactSubmit(val) {
			this.callApi(this.$axios, "f/contact/set", val, { apiKey: "ev-f-con-set" }).then((response) => {
				if (response) {
					this.page.contactRes = response.msg;
				}
			});
		},
		showAllDeals() {
			const deals = true;
			this.$router.push({ name: "Voyages-for-you", query: { deals } });
		},
		getDiscount() {
			this.tours.search.deals = true;
			this.getTours();
		},
		search(val) {
			const {
				continent, country, page, deals, search,
			} = val;
			this.$router.push({
				name: "Voyages-for-you",
				query: {
					continent, country, deals, page, search,
				},
			});
		},
		onIntersect(entries) {
			if (entries[0].isIntersecting) {
				if (this.$router.currentRoute.fullPath !== "/") {
					this.$router.replace({ path: this.$route.path });
				}
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.banner{
		width: 100%;
	}
	.aboutBG{
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		text-align: center;
		align-items: flex-end;
		justify-content: center;
		& svg {
			width: 100%;
			fill: var(--v-bg1-base);
		}
	}

	.about-us{
		position: relative;
		height: 100%;
	}

	.about-contain{
		position: relative;
		z-index: 2;
	}

	.seeAllbtn{
		margin: 16px auto;
	}

	.example-3d {
		width: 100%;
		height: auto;
	}

	.certificate-img{
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		-webkit-box-pack: center;
		max-height: 256px;
		max-width: 256px;
		height: auto;
		width: 256px;
	}
</style>
