<template>
	<div class="contact-us secondaryBG">
		<v-container>
			<v-row class="my-8">
				<v-col cols="12">
					<p class="display-1 text-center">
						Contact US
					</p>
				</v-col>
			</v-row>

			<v-row class="my-8">
				<v-col
					cols="12"
					md="6"
				>
					<v-form
						ref="contactForm"
						lazy-validation
					>
						<v-row>
							<v-col cols="12">
								<v-text-field
									v-model="page.contact.name"
									label="Name *"
									clearable
									:rules="page.validation.requiredField"
								/>
							</v-col>
							<v-col cols="12">
								<v-text-field
									v-model="page.contact.email"
									label="Email *"
									placeholder="yourname@domain.com"
									clearable
									:rules="page.validation.requiredField && page.validation.emailValid"
								/>
							</v-col>
							<v-col cols="12">
								<v-text-field
									v-model="page.contact.mobile"
									label="Mobile *"
									placeholder="+91xxxxxxxxxx"
									clearable
									:rules="page.validation.requiredField && page.validation.mobileValid"
								/>
							</v-col>
							<v-col cols="12">
								<v-textarea
									v-model="page.contact.message"
									label="Message"
									clearable
								/>
							</v-col>
							<v-expand-transition>
								<v-col
									v-if="page.res"
									cols="12"
								>
									<v-alert
										dense
										text
										type="success"
									>
										{{ page.res }}
									</v-alert>
								</v-col>
							</v-expand-transition>
							<v-col cols="12">
								<v-btn
									class="primary"
									:loading="page.loading"
									@click="contactSubmit"
								>
									Send
									<v-icon class="ml-2">
										mdi-send
									</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</v-form>
				</v-col>

				<v-col
					cols="12"
					md="6"
				>
					<iframe
						:src="mapUrl"
						width="100%"
						height="450"
						style="border:0;"
						allowfullscreen=""
						loading="lazy"
					/>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
export default {
	props: {
		mapUrl: { type: String, default: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3765.5621309994185!2d72.850351615399!3d19.301398849863233!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b188ef2fc437%3A0x9a0ce02af44f9184!2sElitevoyages.co.in!5e0!3m2!1sen!2sin!4v1615376910419!5m2!1sen!2sin" },
		resMsg: { type: String, default: null },
	},
	data() {
		return {
			page: {
				contact: {
					name: null,
					email: null,
					mobile: null,
					message: null,
				},
				validation: {
					requiredField: [(v) => !!v || "This Field is Required"],
					emailValid: [(v) => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || "Please enter valid Email ID"],
					mobileValid: [(v) => /^\+[0-9]*$/.test(v) || "please enter valid Mobile Number"],
				},
				res: this.resMsg,
				loading: false,
			},

		};
	},
	watch: {
		resMsg() {
			setTimeout(() => {
				this.page.res = this.resMsg;
				this.page.loading = false;
			}, 3000);
		},
	},
	methods: {
		contactSubmit() {
			if (this.$online) {
				if (this.$refs.contactForm.validate()) {
					this.page.loading = true;
					this.$emit("contactSubmit", this.page.contact);
				}
			}
		},
	},
};
</script>
