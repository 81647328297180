<template>
	<swiper
		class="swiper"
		:options="page.review.swiperOption"
	>
		<swiper-slide
			v-for="(review , n) in reviews"
			:key="n"
		>
			<v-card
				class="mx-auto mb-8 flex d-flex flex-column reviewCard"
				height="100%"
			>
				<v-card-text class="flex">
					<v-icon class="quote-sign">
						mdi-format-quote-open
					</v-icon>

					<div>
						<p class="text--secondary">
							{{ review.testimonial }}
						</p>
					</div>

					<div>
						<v-rating
							:value="parseInt(review.rating)"
							color="primary"
							dense
							readonly
							size="24"
						/>
					</div>
					<v-divider class="ma-4" />
					<v-row>
						<v-col
							cols="12"
							sm="12"
							class="d-flex flex-direction-row"
						>
							<v-avatar
								size="64px"
								class="mr-4"
							>
								<img
									alt="Avatar"
									:src="profile(review.photo_url)"
									@error="loadError"
								>
							</v-avatar>
							<div>
								<div class="title">
									{{ review.name }}
								</div>
								<div>
									{{ review.designation }} <br> {{ review.tour_period }}
								</div>
							</div>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</swiper-slide>

		<div
			slot="pagination"
			class="swiper-pagination"
		/>
	</swiper>
</template>

<script>
export default {
	props: {
		// eslint-disable-next-line vue/require-default-prop
		reviews: { type: Array, required: false },
	},
	data() {
		return {
			page: {
				review: {
					swiperOption: {
						init: true,
						slidesPerView: 3,
						centeredSlides: false,
						spaceBetween: 30,
						loop: true,
						speed: 800,
						loopAdditionalSlides: 1,
						pagination: {
							el: ".swiper-pagination",
							clickable: true,
							dynamicBullets: true,
						},
						preloadImages: true,
						lazy: {
							loadPrevNext: true,
							loadOnTransitionStart: true,
						},
						watchSlidesVisibility: true,
						initialSlide: 0,
						autoplay: {
							delay: 3000,
							disableOnInteraction: false,
							autoplayDisableOnInteraction: false,
						},
						breakpoints: {
							1024: {
								slidesPerView: 3,
								spaceBetween: 30,
							},
							768: {
								slidesPerView: 3,
								spaceBetween: 30,
							},
							640: {
								slidesPerView: 2,
								spaceBetween: 20,
							},
							320: {
								slidesPerView: 1,
								spaceBetween: 10,
							},
						},
					},
				},
			},
		};
	},
	methods: {
		profile(url) {
			return url || "/img/profile.jpg";
		},
		loadError(e) {
			e.target.src = "/img/profile.jpg";
		},
	},
};
</script>

<style lang="scss" scoped>

	.reviewCard{
		background-color: transparent;
		border: 1px solid var(--v-bg2-base);
		padding: 16px;
	}

	.quote-sign{
		color: var(--v-primary-base);
		font-size: 50px;
	}
</style>
