<template>
	<v-row>
		<v-col
			v-for="(type , i) in tourType"
			:key="i"
			cols="12"
			md="4"
		>
			<v-btn
				block
				class="justify-start pa-6 tour-btn"
				plain
				:to="type.s ? `/voyages-for-you?search=${type.n}` : '/#contact' "
			>
				<v-icon class="pr-10">
					mdi-chevron-double-right
				</v-icon>
				{{ type.n }}
			</v-btn>
			<v-divider />
		</v-col>
	</v-row>
</template>

<script>
export default {
	props: {
		// eslint-disable-next-line vue/require-default-prop
		tourType: { type: Array, required: false },
	},
	methods: {
	},
};
</script>

<style scoped lang="scss">
.tour-btn{
	&:hover{
		color: var(--v-primary-base) !important;
	}
}

</style>
